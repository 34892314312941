<template>
	<Dialog :dialog="updatedDialog" :dialog-width="900" @close="$emit('close')">
		<template v-slot:title>Renew Domain </template>
		<template v-slot:body>
			<v-sheet id="lead">
				<v-form
					ref="extendCheckoutForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="renewDomain"
				>
					<div class="">
						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label for="cost" class="input-label">Cost</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<NumberInput
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="cost"
									placeholder="cost"
									v-model="domain.cost"
								>
								</NumberInput>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0 text-right">
								<label for="sell_price" class="input-label text-right">Sell Price</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<NumberInput
									hide-details
									clearable
									id="sell_price"
									:disabled="pageLoading"
									:loading="pageLoading"
									placeholder="Sell Price"
									v-model="domain.sales_price"
								>
								</NumberInput>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label class="input-label">Start Date</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<DatePicker
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="start-date"
									placeholder="Start Date"
									v-model="domain.start_date"
									:disablePastFrom="disablePastDate"
									:max-date="domain.end_date"
								>
								</DatePicker>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0 text-right">
								<label class="input-label">End Date</label>
							</v-col>
							<v-col lg="3" cols="12" class="my-auto py-0">
								<DatePicker
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="start-date"
									placeholder="End Date"
									v-model="domain.end_date"
									:disablePastFrom="disablePastDateEnd"
								></DatePicker>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="py-0">
								<label class="input-label">Remark </label>
							</v-col>

							<v-col lg="9" class="my-auto py-0">
								<TextAreaInput
									:rows="4"
									dense
									ref="comment"
									hide-details
									v-model="domain.remark"
									placeholder="Enter Remark..."
									:disabled="pageLoading"
									:loading="pageLoading"
									:word-limit="200"
								></TextAreaInput>
							</v-col>
						</v-row>
					</div>
				</v-form>
			</v-sheet>
		</template>
		<template v-slot:action>
			<v-btn
				depressed
				:disabled="pageLoading"
				tile
				color="blue darken-4"
				class="text-white my-auto mt-3"
				v-on:click="renewDomain"
				>Save</v-btn
			>
			<v-btn
				depressed
				tile
				:disabled="pageLoading"
				class="my-auto mt-3"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
// import { mapGetters } from "vuex";
// import CreateServer from "@/view/pages/leads/create/create-server";
import { GET, PUT } from "@/core/services/store/request.module";
import Dialog from "@/view/components/Dialog";
import DatePicker from "@/view/components/DatePicker";
import TextAreaInput from "@/view/components/TextAreaInput";
import NumberInput from "@/view/components/NumberInput";
// import MomentJS from "moment-timezone";
// import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
// import { HostingEventBus } from "@/core/lib/hosting.lib";
import MainMixin from "@/core/mixins/main.mixin.js";
/* import { toSafeInteger } from "lodash"; */
export default {
	mixins: [MainMixin],
	name: "update-domain",
	title: "update-domain",
	props: {
		updatedDialog: {
			type: Boolean,
			default: false,
		},
		detailId: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			domain: {
				cost: null,
				sales_price: null,
				end_date: null,
				start_date: null,
				remark: null,
			},
			end_date: null,
			serverProvider: [],
			disablePastDate: null,
			disablePastDateEnd: null,
		};
	},

	components: {
		Dialog,
		NumberInput,
		TextAreaInput,
		DatePicker,
		// CreateServer,
	},
	mounted() {
		if (this.detailId) this.getDomain();
	},
	watch: {
		"domain.start_date": {
			handler: function (param) {
				this.disablePastDateEnd = this.calculate_next_day(param);
			},
		},
	},
	methods: {
		renewDomain() {
			const payload = {
				id: this.detailId,
				cost: this.domain.cost,
				sales_price: this.domain.sales_price,
				end_date: this.domain.end_date,
				start_date: this.domain.start_date,
				remark: this.domain.remark,
			};

			const _this = this;
			_this.$store
				.dispatch(PUT, { url: `domain-renew`, data: { ...payload } })
				.then((data) => {
					console.log({ data });
					this.$emit("success", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getDomain() {
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, { url: `domain-details/${this.detailId}` })
				.then((data) => {
					let date = _this.calculate_next_day(data.end_date);

					_this.domain.cost = data.cost;
					_this.domain.sales_price = data.sales_price;
					// _this.domain.end_date = data.end_date;
					_this.disablePastDate = data.start_date ? date : null;
					_this.domain.start_date = data.start_date ? date : null;
					_this.domain.remark = data.remark;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},

	// computed: {
	// 	...mapGetters(["errors"]),
	// },
};
</script>
